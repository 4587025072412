html {
  height: 100%;
}

body {
  font-family: 'Poppins';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  background: #f9f6fb;
}

#root {
  flex: 1 0 auto;
}
