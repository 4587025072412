.button input[type='radio'] {
  display: none;
}
input[type='radio']:checked + label {
  background-image: url('../../assets/icons/checkMark.svg');
  background-repeat: no-repeat;
  background-position: right;
}
.label {
  position: relative;
  z-index: 1;
}
.img {
  position: relative;
  z-index: -1;
  width: 55px;
}
p {
  margin-top: 0px;
  font-size: 12px;
}
